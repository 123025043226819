import React from 'react'

import { Link } from 'gatsby'

import Layout from '../containers/layout'
import SEO from '../components/seo'

const HeadingClass = 'dib relative w-100 f2 fw5 mt0 mb2'
const ParagraphClass = 'dib relative w-100 measure-wide gray fw4 mb4'
const ButtonClass = 'dib relative w-100 w-auto-l brand-red tc ph3 pv2 br-pill b link dim pointer'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <article>
      <section className="dt relative vh-100 w-100 bg-white black lh-copy f5" id="home">
        <div className="dtc v-mid w-100 relative tc">
          <div className="dib relative w-100">
            <h1 className={HeadingClass}>Well there's nothing here friends... except this GIF</h1>
            <p className={ParagraphClass}>In some weird way you've stumbled upon something cool!</p>
          </div>
          <img
            className="db center mw5 pa4"
            src="https://media.giphy.com/media/U5becYGFWmWHRZeFOE/giphy.gif"
          />
          <Link className={`bg-brand-pink ${ButtonClass}`} to="/">
            Go back home
          </Link>
        </div>
      </section>
    </article>
  </Layout>
)

export default NotFoundPage
